.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-slider {
  width: 100%;
}

.slider-icon {
  min-height: 30rem;
}

.slider-icon img {
  height: 100%;
  display: block;
  position: absolute;
  left: -50%;

}

.content-slider-wrapper {
  display: block;
  text-align: center;
  align-items: center;
  position: relative;
}

.slider-content {
  z-index: 5;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  display: block;
  height: 100%;
  width: 100%;
  bottom: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.slider-content-inner {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  display: inline-block;
}

.slider-background-text {
  /*padding: 1rem 1rem;*/
  width: auto;
}

.slide-titel {
  /*font-family: 'Julius Sans One', sans-serif;*/
  font-size: 25px;
  color: white;
  text-transform: uppercase;
  font-weight: 100;
  margin-bottom: 0.5rem;
  letter-spacing: 2px;
}

.slider-content p {
  /*font-family: 'Julius Sans One', sans-serif;*/
  color: white;
  margin-bottom: 0;
  font-size: 20px;
  letter-spacing: 1px;
}

.slide--button-container {
  position: absolute;
  top: 75%;
  width: 100%;
}

.slider-content .btn {
  margin-right: auto;
  margin-left: auto;
  display: inline-block;
  margin-top: 1rem;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
}

.slider-content .btn:hover {
  border: solid 5px #4f5356;
  color: white;
}

.slider-background-blur {
  z-index: -1;
  position: absolute;
  /*filter: blur(10px);*/
  /*-webkit-filter: blur(10px);*/
  /*-moz-filter: blur(10px);*/
  /*-o-filter: blur(10px);*/
  /*-ms-filter: blur(10px);*/
  /*filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='10');*/
  overflow: hidden;
  top: 0;
  left: 0;
  background-size: cover !important;
  background: transparent no-repeat center;
  height: 30rem;
}

.slider-background {
  margin: auto;
  overflow: hidden;
  display: block;
  position: relative;
  width: 100%;
}

.slider-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.swiper-slide {
  overflow:  hidden;
  max-height: 883px;
}

.swiper-container {
  max-height: 883px;
}

@media screen\0 {
  .slider-background-blur {
    background: rgba(0, 0, 0, 0.9) !important;
  }

  .slider-background {
    margin: 0;
  }

  .content-slider-wrapper {
    position: relative;
  }

  .slide--button-container {
    display: block !important;
    width: 100%;
    left: 0;
  }

  .slide--button-container a {
    margin-top: 0 !important;
    text-align: center;
    display: inline-block !important;
    position: relative !important;
  }
}

/*@media screen and (min-width: 35.5rem) {*/
  .slider-background {
    width: auto;
  }

  .slider-icon,
  .slider-icon img {
    width: 100%;
    position: relative;
    height: auto;
    min-height: inherit;
    left: inherit;
  }

  .slider-icon img {
    max-width: 100%;
    max-height: none;
    height: auto;
  }
  .slider-icon,
  .slider-icon img {
    /*width: 100%;*/
    position: relative;
    height: auto;
    min-height: inherit;
    left: inherit;
  }
  .slider-icon {
   /* height: 900px;*/
    width: 100%;
  }
  .slider-icon img {
    max-width: none;
   /* max-height: 900px;*/
    height: auto;
    width: 100%;
  }

  .slide-titel {
    font-size: 42px;

  }

  .slider-content p {
    font-size: 29px;
  }

  .slider-content .btn:hover {
    border: none;
  }

  .slide-titel {
   /* font-size: 26px;*/
    margin-bottom: 0.5rem;
  }

  .slider-content p {
    /*font-size: 20px;*/
    line-height: 2rem;
  }
/*}*/



@media screen and (min-width: 48rem) {
  .slider-background-blur {
    top: 0;
    left: 0;
    bottom: 20%;
    right: 0;
  }

  .slide--button-container {
    top: 70%;
  }
}

@media screen and (min-width: 64rem) {
  .slider-icon img {
    /*max-height: 1000px;*/
  }
  .slider-icon {
    /*height: 1000px;*/
  }
  .slide-titel {
    font-size: 40px;
    margin-bottom: 0.5rem;
  }

  .slider-content p {
    font-size: 29px;
  }

  .slider-background-blur {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .slide--button-container {
    top: 65%;
  }

  .slider-content .btn {
    height: auto;
    width: auto;
    padding: 1rem 1.5rem;
    border: none;

  }
}
@media screen and (min-width: 80rem) {
  .slider-icon img {
    max-height: none;
  }
  /*.slider-icon {
    height: 100vh;
  }*/
}

/* sponsor slider footer */
.sponsor-slider .swiper-slide {
  background: transparent;
}
