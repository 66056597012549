.kacheln {
    margin-top: var(--gutter);
}

@media screen and (--viewport-lg) {
    #boxen-section {
        width: 60.2em;
        margin: 0 auto;
    }
}

@media screen and (--viewport-xl) {
    #boxen-section {
        width: 75.2em;
    }
}

.boxen {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-direction: row;
    -webkit-flex-direction: row;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    align-content: flex-start;
    -webkit-align-content: flex-start;
    align-items: stretch;
    -webkit-align-items: stretch;
}

.box-news--img img,
.box-leistung--img img {
    width: 100%;
}

.box {
    width: 100%;
    margin-bottom: 0.40rem;
    display: block;
    min-height: 270px;
    align-self: stretch;
    visibility: visible;
    opacity: 1;
    overflow: hidden;
    transition: visibility 0s, opacity 0.5s linear, width 0.5s linear, height 0.5s linear;
}

.box-inner {
    height: 100%;
    width: 100%;
    margin-right: 0;
    background: white;
    position: relative;
    display: block;
    overflow: hidden;
}

/*
* Leistungs Box
*/
.box-leistung--content {
    margin: 12.5% 12.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    height: 75%;
    border: 1px solid transparent;
    transition: border-color 0.55s ease;
    width: 75%;
    overflow: hidden;
    z-index: 2;
}

.box-leistung .box-inner:hover .box-leistung--content {
    border-color: white;
}

.box-leistung--inner-blur {
    position: absolute;
    top: -17.5%;
    right: -17.5%;
    bottom: -17.5%;
    left: -17.5%;
    z-index: 10;
    filter: blur(10px);
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='10');
    background-size: cover !important;
    background: transparent no-repeat center;
    opacity: 0;
    transition: opacity 0.55s ease;
}

.box-leistung .box-inner:hover .box-leistung--inner-blur {
    opacity: 1;
    background-size: 110% !important;
}

.box-leistung-img-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -8px;
    background-size: 104%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    transition: background-size 0.5s linear;
}

.box-leistung .box-inner:hover .box-leistung-img-hover {
    background-size: 110%;
}

.box-leistung--inner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    z-index: 12;
    opacity: 1;
    position: absolute;
    top: 68%;
    left: 0;
    transition: top 0.55s ease;
}

.box-leistung .box-inner:hover .box-leistung--inner {
    opacity: 1;
    top: 0;
}

.box-leistung--inner h3 {
    /*font-family: 'Julius Sans One', sans-serif;*/
    font-size: 1.5rem;
    color: white;
    text-transform: uppercase;
    width: 100%;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    opacity: 1;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .8);
}

.box-leistung--inner p {
    opacity: 0;
    color: white;
    margin-bottom: 0;
    transition: opacity 0.55s, top 0.55s ease;
    font-size: 0.9rem;
    line-height: 1.1rem;
    width: 90%;
    display: block;
}

.box-leistung .box-inner:hover .box-leistung--inner p {
    opacity: 1;
    text-shadow: 2px 1px 1px black;
}

.box-leistung---link-container {
    position: absolute;
    background: transparent;
    bottom: 6%;
    z-index: 12;
    transition: opacity 1s ease;
    width: 100%;
    display: block;
}

.btn-leistung {
    z-index: 12;
    background: white;
    padding: 0.5rem 2rem;
    opacity: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    transition: opacity 1s ease, color 0.5s ease, background 0.5s ease;
}

.btn-leistung:hover {
    background: var(--color-primary);
    color: white;
}

.box-leistung .box-inner:hover .btn-leistung {
    opacity: 1;
}

/* Text Box*/
.box-text .box-inner {
    position: absolute;
    /*width: 100%;*/
    height: 100%;
}

.box-text .box-text--content {
    padding: 1rem;
    height: 100%;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.box-text p {
    /*font-family: 'Julius Sans One', sans-serif;*/
    font-size: 1rem;
    line-height: 1.5rem;
    color: black;
    text-transform: uppercase;
    margin-bottom: 0;
}

/* Box news*/
.box-news .box-inner--content {
    min-height: 19rem;
    height: 100%;
    overflow: hidden;
    display: block;
    position: relative;
}

.box-news--content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
    min-height: 4.5rem;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 12;
    background: white;
}

.box-news--con-inner h3 {
    width: 100%;
    font-size: 1.35rem;
    margin-bottom: 1.3rem;
}

.box-news--con-inner {
    width: 100%;
    position: absolute;
    top: 49%;
    bottom: 0;
    background: white;
    text-align: center;
    padding: 0.5rem;
    transition: top .5s ease;
}

.box-news .box-inner:hover .box-news--con-inner {
    top: 15%;
}

/* Box Kurse */
.box-kurs .box-kurs--content {
    padding: 1rem;
    height: 100%;
}

.box-kurs .box-inner {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.box-kurs .box-inner table {
    margin-bottom: 0;
}

.box-kurs .box-inner .kurs-box--hours {
    border-bottom: 1px solid #e0e0e0;
    padding: 0.5rem;
    width: 100%;
}

.box-kurs .box-inner .kurs-box--contact {
    line-height: 1rem;
    font-size: 0.95rem;
    width: 100%;
    padding: 0.5rem;
}

.box-kurs h3 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    width: 100%
}

.box-kurs p {
    margin-bottom: 0.5rem;
}

.kurse-table {
    border-collapse: collapse;
    text-align: left;
    margin-bottom: 0.3rem;
}

.kurs-box--hours table td:nth-child(2) {
    /* color: white; */
    text-align: right;
}

.kurse-table tr {
    border-bottom: 1px solid #e0e0e0;
}

.kurse-table td {
    padding-bottom: 0.7rem;
}

.kurse-table tr:last-of-type {
    border-bottom: none
}

.kurse-table a {
    color: var(--color-primary);
}

.kurse-table a:hover {
    color: color(var(--color-primary) a(80%));
}

.kurs-start-link {
    display: block;
    margin-bottom: 0.5rem;
    bottom: 8px;
    position: absolute;
}

.kurs-start-link .btn {
    display: inline-block;
    width: auto;
    padding: 0.6rem 2.73rem;
    margin-top: 5px;
}

.hidden-info {
    display: none;
}

.kacheln-toggle {
    background: white;
    padding: 1rem;
    margin-bottom: 1rem;
}

.kachlen-menu {
    list-style: none;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    padding-left: 0;
    margin-top: var(--gutter);
}

.kachlen-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border: solid 1px #bfbfbf;
    border-radius: 0;
    padding: 0.3rem 2rem 0.3rem 1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 16px;
    color: #bfbfbf;
    transition: color 0.55s;
    cursor: pointer;
    width: auto;
}

.kachlen-menu-item > span {
    margin-right: 0.5rem;
}

.toggle-cross-line {
    backface-visibility: hidden;
    position: absolute;
    left: 50%;
    border-top: 2px solid #bfbfbf;
    transition: 0.55s;
    width: 14px;
}

.toggle-cross-top {
    transform: rotate(90deg);
}

.kachlen-menu-item.active,
.kachlen-menu-item:hover {
    color: var(--color-primary);
    border: solid 1px var(--color-primary);
}

.kachlen-menu-item.active .toggle-cross-line,
.kachlen-menu-item:hover .toggle-cross-line {
    border-top: 2px solid var(--color-primary);
}

.kachlen-menu-item.active .toggle-cross-top {
    transform: rotate(135deg);
}

.kachlen-menu-item.active .toggle-cross-bottem {
    transform: rotate(45deg);
}

.news-hidden {
    display: none;
}

.box-kurs .box-inner {
    position: relative;
}

@media screen\0 {
    .box-leistung--inner-blur {
        background: rgba(0, 0, 0, 0.9) !important;
    }
}

@media screen and (min-width: 35.5rem) {
    .box {
        width: 50%;
        margin-bottom: 0.40rem;
    }

    .box .box-inner {
        margin-left: 0;
        margin-right: 0.20rem;
    }

    .box--start .box-inner {
        margin-right: 0;
        margin-left: 0.20rem;
    }

    .box:not(.box-kurs):nth-of-type(even) .box-inner {
        margin-left: 0.20rem;
        margin-right: 0;
    }

    .box--start:nth-of-type(odd) .box-inner {
        margin-right: 0.20rem;
        margin-left: 0;
    }

    .box-kurs {
        width: 100%;
        text-align: left;
    }

    .box-kurs .box-inner {
        margin-right: 0;
        margin-left: 0;
    }

    .kachlen-menu {
        justify-content: center;
        align-items: center;
    }

    .kachlen-menu-item {
        width: auto;
    }

    .hidden-info {
        display: block;
    }

    .box-kurs .box-inner {
        position: absolute;
    }

    .box-kurs .box-inner .kurs-box--hours {
        border-right: 1px solid #e0e0e0;
        border-bottom: none;
        padding: 0 1rem 0 2.5rem;
        width: 50%;
    }

    .box-kurs .box-inner .kurs-box--contact {
        line-height: 1rem;
        font-size: 0.95rem;
        width: 50%;
        padding: 4rem 0 0 1rem;
    }
}

@media screen and (min-width: 48rem) {
    .box-leistung--inner p {
        font-size: 1rem;
        line-height: 1.3rem;
    }


}

@media screen and (min-width: 55rem) {
    .box {
        width: calc(100% / 3);
    }

    .box .box-inner,
    .box--start:nth-of-type(odd) .box-inner {
        margin-left: 0.20rem;
        margin-right: 0.20rem;
    }

    .box:not(.box-kurs):nth-of-type(even) .box-inner {
        margin-left: 0.20rem;
        margin-right: 0.20rem;
    }

    .box--start:nth-of-type(odd) .box-inner {
        margin-right: 0.20rem;
        margin-left: 0.20rem;
    }

    /*.box:not(.box-kurs):nth-of-type(3n-2) .box-inner {
      margin-left: 0;
    }
    .box:not(.box-kurs):nth-of-type(even) .box-inner {
      margin-right: 0.20rem;
    }
    .box:not(.box-kurs):nth-of-type(3n-3) .box-inner {
      margin-right: 0;
    }
    .box-news .box-inner {
      margin-left: 0;
    }
    !*.box:not(.box--start):nth-of-type(3n+1) .box-inner {
      margin-left: 0;
    }*!

    .box--start:nth-of-type(3n-2) .box-inner {
      margin-left: 0;
    }

    .box--start:nth-of-type(3n-3) .box-inner {
      margin-right: 0;
    }*/
    .box-news:nth-of-type(2) {
        display: none;
    }

    .news-hidden {
        display: block;
    }

    .box-kurs {
        /* width: calc(100%/3 - 1%);*/
        width: calc((100% / 3) * 2);
        text-align: left;
    }

    .box-leistung--inner p {
        font-size: 0.95rem;
        line-height: 1.1rem;
    }

    .hidden-info {
        display: block;
    }


}

@media screen and (min-width: 80rem) {
    .box {
        width: calc(25%);
        /* margin-left: 0.20rem;
         margin-right: 0.20rem;*/
    }

    /*.box:not(.box-kurs):nth-of-type(3n-2) .box-inner,
    .box--start:nth-of-type(3n-2) .box-inner,
    .box--start:nth-of-type(3n-3) .box-inner,
    .box .box-inner {
      margin-left: 0.20rem;
      margin-right: 0.20rem;
    }
    .box:not(.box-kurs):nth-of-type(3n-3) .box-inner {
      margin-right: 0.20rem;
    }
    .box:not(.box--start):nth-of-type(4n+1) .box-inner {
      margin-left: 0;
    }

    .box--start:nth-of-type(5n-1) .box-inner {
      margin-right: 0;
    }
    .box--start:nth-of-type(4n+1) .box-inner {
      margin-left: 0;
    }*/
    .box-kurs {
        width: 50%;
        text-align: left;
    }

    .box-kurs .box-inner {
        margin-right: 0;
        width: 99%;
    }

    .box-news:nth-of-type(2) {
        display: block;
    }

    .news-hidden {
        display: none;
    }

    .hidden-info {
        display: block;
    }
}

.hidden-box {
    display: none;
}
