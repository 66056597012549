
.side-box {
  position: fixed;
  right: 0;
  background: #312d30;
  padding: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 1000;
  width: 24.5%;
  height: 3rem;
  transition: right 0.55s,background 0.5s, border 0.5s;
  bottom: 0;

}

.side-box:hover,
.side-box-highlight {
  background: var(--color-primary);
}

.side-mail {
  right: 75%
}

.side-kurs {
  right: 50%
}

.side-probe {
  right: 25%
}

.side-mitglied {
  right: 0;
}

.side-box img {
  height: 2rem;
  width: 2rem;
}

.side-box a {
  color: white;
}

.side-box a:hover {
  color: white;
}

.side-box span {
  display: none;
}

@media screen and (min-width: 48rem) {
  .side-box img {
    height: 1.5rem;
    width: 1.5rem;
  }
  .side-box {
    right: -9.5rem;
    bottom: auto;
    top: 10rem;
    justify-content: flex-start;
    align-content: flex-start;
    width: 12rem;
    height: auto;
  }

  .side-box span {
    margin-left: 1rem;
    color: white;
    display: block;
  }

  .side-mail {
    top: 10rem
  }

  /*.side-kurs {*/
    /*top: 12.6rem*/
  /*}*/

  .side-probe {
    /*top: 15.25rem*/
    top: 12.6rem
  }

  .side-mitglied {
    /*top: 17.85rem*/
    top: 15.25rem
  }

  .side-box:hover {
    right: 0;
  }
}