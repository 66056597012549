.menubox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
  position: relative
}

.menubox--link {
  width: calc(100% / 3);
  margin-bottom: 0.3rem;
  position: relative;
  display: block;
  transition: background 0.5s;
}

.menubox--link--inner {
  min-height: 100px;
  height: 100%;
  background: #353134;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding: .5rem;
  margin-bottom: 0.3rem;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
  line-height: 1rem;
  text-align: center;
  transition: background 0.5s ease;
}

.menubox--link:first-of-type .menubox--link--inner {
  margin-left: 0;
}

.menubox--link:last-of-type .menubox--link--inner {
  margin-right: 0;
}
.menubox--link:nth-of-type(3n) .menubox--link--inner {
  margin-right: 0;
}
.menubox--link:hover .menubox--link--inner {
  background: var(--color-primary);
}

.menubox--img {
  height: 2rem;
  width: 2rem;
}

.menubox-titel {
  color: white;
  font-size: 0.8rem;
  padding-top: 0.5rem;
}

@media screen and (min-width: 48rem) {
  .menubox--link--inner {
    min-height: 100px;
  }

  .menubox--link {
    width: calc(100% / 6);
  }
  .menubox--link:nth-of-type(3n) .menubox--link--inner {
    margin-right: 0.15rem;
  }
  .menubox--link:nth-of-type(6n) .menubox--link--inner {
    margin-right: 0;
  }
}

@media screen and (min-width: 64rem) {
  .menubox--link--inner {
    min-height: 90px;
  }

  .menubox--link {
    width: calc(100% / 6);
  }
}

@media screen and (min-width: 80rem) {
  .menubox {
    flex-wrap: nowrap;
  }
  .menubox--link:nth-of-type(3n) .menubox--link--inner {
    margin-right: 0.15rem;
  }
  .menubox--link:nth-of-type(6n) .menubox--link--inner {
    margin-right: 0.15rem;
  }
  .menubox--link {
    width: calc(100% / 12);
  }
}