header {
    background: #fff;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
    -moz-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
    -webkit-box-shadow: 0 1px 14px 0 rgba(0, 0, 0, .55);
}

.mobile-navigation {
    background: color(var(--bg-color-body) a(90%))!important;
}

.mobile-navigation ul {
    list-style-type: none;
}

.main-nav {
    text-transform: uppercase;
}

.header-logo-mobil {
    margin-left: 1rem;
    padding: 0.2rem;
}

.header-logo-mobil a {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.header-logo-mobil img {
    height: 3rem;
}

.hamburger-button svg {
    width: 2rem;
    height: 2rem;
    display: block;
    margin: auto auto;
}

.hamburger-button {
    height: 2.5rem;
    width: 2.5rem;
    outline: none;
    border: medium none;
    color: #f5f5f5;
    font-weight: 700;
    padding: 0;
    cursor: pointer;
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 1rem;
}

.hamburger-button {
    background-color: var(--color-secondary);
}

.hamburger-button:hover {
    background-color: color(var(--color-secondary) a(80));
}

ul.main-navigation {
    list-style: none;
    padding-left: 0;
    margin-top: var(--gutter);
}

.main-navigation li:not(.header-logo) > a {
    font-size: 1rem;
    color: var(--font-color);
}

.main-navigation li > a:hover,
.main-navigation li > a:focus,
.main-navigation li > a:active,
.main-navigation li.active > a {
    color: var(--color-primary);
}

/*.main-navigation li > a:active {*/
    /*position: relative;*/
/*}*/
/*.main-navigation li > a:active::after {*/
    /*content: '';*/
    /*position: absolute;*/
    /*bottom: -4px;*/
    /*left: 50%;*/
    /*background-color: var(--color-primary);*/
    /*transform: translateX(-50%);*/
    /*height: 2px;*/
    /*width: 26px;*/

/*}*/

.menu-img {
    /*background: #383437;*/
    background: white;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-right: 0.5rem;
    margin-left: 0;
    transition: background 0.5s, border 0.5s;
}

.main-navigation li > a:hover .menu-img,
.main-navigation li.active > a .menu-img {
    background: var(--color-primary);

}

.menu-icon {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
}

.menu-icon img {
    height: 0.8rem;
    width: 0.8rem;
}

.dropdown {
    position: absolute;
    left: 0;
    margin-bottom: 0;
    width: 100%;
    padding: var(--gutter-horizontal);
    list-style-type: none;
}

.dropdown-list-item {
    padding: 0 var(--gutter);
}

.dropdown > li:last-of-type {
    border-right: none;
}

.dropdown > li:first-of-type {
    /*padding-left: 0;*/
}

.dropdown-list-item ul {
    font-size: var(--font-size-small);
    list-style-type: disc;
}

.dropdown-link {
    display: block;
    color: var(--font-color);
    text-decoration: none;
    padding: 0.5rem;
}

.submenu-2 {
    margin-bottom: var(--gutter-horizontal);
}

.dropdown-link-layer-2 {
    color: var(--font-color);
    text-decoration: none;
}

.dropdown-link:hover,
.dropdown-link-layer-2:hover {
    color: var(--color-primary);
}

.main-navigation .open {
    /*background-color: var(--color-secondary);*/
    color: white;
}

.main-navigation .open + .dropdown {
    display: flex;
    flex-wrap: wrap;
    z-index: 10;
}


.main-navigation li:not(.header-logo) > a:focus {
    position: relative;
}

.main-navigation li:not(.header-logo) > a:focus::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 50%;
    background-color: var(--color-primary);
    transform: translateX(-50%);
    height: 2px;
    width: 26px;
}


@media screen and (min-width: 48rem) {
    .main-nav {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    ul.main-navigation {
        display: flex;
        width: 100%;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0;
        margin-top: 0;
    }

    ul.main-navigation li {
        display: inline-block;
    }

    ul.main-navigation > li {
        display: inline-block;
        border-right: 1px solid #e0e0e0;
        height: 5rem;
        position: relative;
    }

    ul.main-navigation > li:first-of-type {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    ul.main-navigation > li:not(.header-logo) > a {
        display: flex;
        height: 100%;
        text-align: left;
        padding: 1rem 0.5rem;
        line-height: 1rem;
        font-weight: 400;
    }

    ul.main-navigation > li:not(.header-logo) > a:hover {
        color: var(--color-primary);
    }

    /* 2. Ebene */
    .dropdown {
        display: none;
        position: absolute;
        left: 0;
        background-color: white;
        margin-bottom: 0;
        width: 19rem;
        padding: 0;
        list-style-type: none;
        z-index: 100;
    }

    ul.main-navigation li .dropdown-link,
    ul.main-navigation li:hover .dropdown-link,
    ul.main-navigation li .dropdown-link-layer-2,
    ul.main-navigation li:hover .dropdown-link-layer-2 {
        color: var(--color-secondary);
    }

    ul.dropdown li {
        display: block;
        width: 100%;
        padding: 0;
        margin-left: 0;
        font-size: var(--font-size);
    }

    ul.dropdown li a:hover {
        color: var(--color-primary) !important;
    }

    .main-navigation .open {
        background-color: transparent;
        color: white;
    }

    .main-navigation ul {
        list-style-type: none;
    }

    .main-navigation ul li {
        display: inline-block;
        margin-top: .5rem;
        padding: 0 .35rem;
    }

    .main-navigation ul {
        padding-left: 2rem;
    }
}

@media screen and (min-width: 64rem) {
    /*ul.main-navigation {
      width: 80%;
    }*/
    ul.main-navigation > li:not(.header-logo) > a {
        padding: 1rem 1rem;
    }

    .main-navigation ul {
        padding-left: 2rem;
    }

    /*.header-logo a {*/
        /*top: -3.1rem;*/
        /*left: -1.8rem;*/
    /*}*/

    /*.header-logo.normal img {*/
        /*height: 7.8rem;*/
        /*transition: height 0.5s ease;*/
    /*}*/

    /*.header-logo.shrink img {*/
        /*height: 5.8rem;*/
        /*transition: height 0.5s ease;*/
    /*}*/
}

/* 1280 px*/
@media screen and (min-width: 80rem) {
    .main-navigation ul {
        padding-left: 12rem;
    }
}

@media screen and (min-width: 90rem) {
    .main-navigation ul {
        padding-left: 12rem;
    }
}


.dropdown.first {
  display:  none;
  padding-left: 0;
  box-shadow: 0 10px 10px -8px rgba(0,0,0,.55);
}

.dropdown {
  background:  #222;
}

.main-navigation ul li.dropdown-list-item {
  margin-top:  0;

}

@media screen and (min-width: 48rem) {
  .dropdown {
    top: 48px
  }
}

/* LANDING PAGE */


.header-logo.normal {
    width: 100px;
}

.header-logo.normal img {
    width: 100%;
    height: auto;
}

.main-nav .main-navigation > .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.main-navigation li:not(.header-logo) > a {
    font-weight: 400;
}

.contact-btn__container {
    display: none;
}

#nav-contact {
    display: none;
}

@media screen and (min-width: 768px ){
    #nav-contact {
        font-weight: 400;
        color: #fff;
        display: initial;
    }
    .contact-btn__container {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background: var(--color-primary);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }
    .main-menu-link.main-menu-link__contact {
        display: none;
    }
}

.header-logo-mobil {
    margin: auto;
}