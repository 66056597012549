/*
 * Filename: typo.css
 *
 * Primary stylesheet which imports every other file.
 */

body {
  font-family:'Proxima Nova W01';
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-weight: 200;
  color: var(--font-color);
  letter-spacing: -0.02em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin-top: 0;
  margin-bottom: var(--gutter-horizontal);
}

h1 {
  font-size: var(--font-size-heading);
  font-weight: 200;
  line-height: 1.0em
}

h2 {
  /*font-size: calc(var(--font-size-subheading) * 1);*/
  /*line-height: calc(var(--line-height) * .8);*/
  font-size: 20px;
    line-height: 28px;
    font-weight: 800;
    color: var(--color-primary);
    text-transform: uppercase;
}

h3 {
  font-size: var(--font-size-subheading);
  font-weight: 200;
}

h5,
h6 {
  font-weight: 200;
}

small {
  font-size: var(--font-size-small);
}

ul {
  padding-left: var(--gutter-horizontal);
}
.pure-g [class *= "pure-u"] {
  font-family: 'Proxima Nova W01';
}