.contact {
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.maps-container {
    height: 20rem;
}

form#contact p:not(.status-message) {
    font-weight: 400;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
    color: #7c7c7c;
}

.contact--input-container {
    margin-bottom: 1rem;
}

label {
    color: #7c7c7c;
    padding-bottom: 0;
    margin-bottom: 0;
}

.contact button {
    margin: auto;
    margin-top: 1rem;
    display: block;
}

#contact-uhrzeit {
    margin-bottom: 0;
}

.status-message {
    font-weight: bold;
    font-size: var(--font-size);
    margin-top: calc(var(--gutter) * 0.5);
    color: #ffffff;
    padding: 0.5rem;
}

.status-message.error {
    background-color: var(--error);
    color: white;
}

.status-message.success {
    background-color: var(--success);
    color: white;
}

.contact form#contact label[for="infoFormNachricht"] > span,
.contact form#contact label[for="infoFormBetreff"] > span {
    width: 130px;
}

.contact-heading {
    text-align: center;
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
}

.contact-heading > span {
    padding: 0 16px;
    background: #f4f6f8;
    z-index: 3;
    display: inline;
}

.contact-heading::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: #000;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
}

@media screen and (max-width: 567px) {
    .contact-heading > span {
        display: block;
    }
    .contact {
        padding-left: 0px;
        padding-right: 0;
    }
}

/* 1024 px - Laptop */
@media screen and (min-width: 64rem) {
    .contact {
        padding: 1rem 2rem 2rem 2rem;
    }

}