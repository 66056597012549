.kurse {
  margin-bottom: 2rem;

}
.kurse-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  left: 0;
  overflow: hidden;
}
.kurse-box {
  width: 100%;
}
.kurs-list {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}


.kurse-header {
  padding: .5rem .3rem;
  border-right: 1px solid #e0e0e0;
  text-align: center;
  background: var(--color-primary);
  color: white;
  margin: 0.5rem 0;
}


.kurse-date {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: .5rem 1rem .5rem 1rem;
}
.kurs-list li {
  display: block;
  text-align: center;
  padding: .5rem .3rem;
  background: #ffffff;
  margin: 0.5rem 0;
  height: 4rem;
}
.kurs-time,
.kurs-name {
  display: inline-block;
  width: 100%;
}
.kurse-content  a {
  color: var(--color-primary);
}
.kurse-content  a:hover {
  color: color(var(--color-primary) a(80%));
}

@media screen and (min-width: 35.5rem) {
  .kurse-header,
  .kurs-list li {
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
  .kurse-box {
    width: calc(100%/4);
  }
}
@media screen and (min-width: 48rem) {

  .kurse-box {
    width: calc(100%/7);
  }
  .kurs-list li {
    height: 6.5rem;
  }
}
@media screen and (min-width: 80rem) {
  .kurse-box {
    width: calc(100%/7);
  }
  .kurs-list li {
    height: 4rem;
  }
}