
footer {
    /*position: relative;*/
    /*background-color: var(--bg-color-body);*/
    /*padding-top: calc(var(--gutter) * 2);*/
    /*padding-bottom: calc(var(--gutter) * 2);*/
    /*margin-bottom: 4rem;*/

}

.footer-content-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    color: #fff;

    font-size: 1rem;
    text-align: center;
    font-weight: 200;
    padding-top: 2rem;
}

.footer-content-wrapper p {
    width: 100%;
    line-height: 1.5;
}

.footer-content-wrapper ul {
    margin-bottom: 0;
    list-style-type: none;
    padding-left: 0;
    width: 100%;
}

.footer-content-wrapper a {
    color: #fff;
    font-size: 1rem;
}

.footer-content-wrapper a:hover {
    color: var(--color-primary);
}

.social-icon {
    background: white;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    transition: background 0.5s, border 0.5s;
}

.footer-content-wrapper a:hover .social-icon {
    background: var(--color-primary);
    border: 8px solid var(--color-primary);
}

.social-icon img {
    height: 1rem;
    width: 1rem;
}

.social-menu li {
    margin-top: 1rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.social-menu a {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}

.social-text {
    margin-top: 0.5rem;
}

.kontakt-footer {
    padding-top: calc(var(--gutter) * 2.5);
    padding-bottom: calc(var(--gutter) * 2.5);
}

footer {
    overflow: hidden;
}

.footer-logo {
    max-width: 160px;
}

/*footer::before {*/
/*content: '';*/
/*display: block;*/
/*position: absolute;*/
/*left: 0;*/
/*bottom: 0;*/
/*width: 100%;*/
/*height: 100%;*/
/*opacity: 0.2;*/
/*background-image: url('img/footer-tempel1.png');*/
/*background-position: 50% 0;*/
/*background-repeat: no-repeat;*/
/*background-size: cover;*/
/*}*/

/*.footer-logo {*/
    /*width: 10rem;*/
    /*height: 100%;*/
/*}*/

.footer-content-wrapper ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.footer-content-wrapper ul.social-menu {
    align-content: stretch;
    align-items: stretch;
}

.footer-content-wrapper ul li {

}

.footer-content-wrapper ul:not(.social-menu) li:after {
    content: '·';
    padding-right: 0.3rem;
    padding-left: 0.3rem;
}

.footer-content-wrapper ul li:last-of-type:after {
    content: '';
    padding: 0;
}

.kontakt-footer-inner {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.kontakt-footer h6 {
    margin-bottom: calc(var(--gutter) * 3);
}

.kontakt-footer-content {
    width: 33%;
    margin-top: 0;
}

.footer-schriftzeichen {
    display: none;
}

footer .wrapper--sponsers {
    background: var(--bg-color-header);
    padding-top: calc(var(--gutter) * 2);
}

.sponsors {
    display: flex;
    flex-wrap: wrap;
}

.sponsor {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.sponsor .sponsors--text {
    text-align: left;
    text-transform: uppercase;
    padding-left: 1.5rem;
    padding-right: 3rem;
    width: 75%;
}

.sponsor .sponsors--text p,
.sponsor .sponsors--text a,
.sponsor .sponsors--text p a {
    color: white;
}

.sponsor .sponsors--text a:hover,
.sponsor .sponsors--text p a:hover {
    color: var(--color-primary);
}

.sponsor .sponsors--logo {
    width: 25%;
}

.sponsor .sponsors--logo img {
    width: 100%;
    height: auto;
}

/*.footer__contact {*/
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  /*width: 50%;*/
  /*color: #fff;*/
/*}*/

/*.footer__contact a {*/
  /*color: #fff;*/
/*}*/

/*.footer__contact a:hover {*/
  /*color: var(--color-primary);*/
/*}*/

/*.footer__contactinfo {*/
  /*padding-left: 16px;*/
/*}*/

.footer__wrapper {
  display: flex;
}

.footer-sponsor-slider {
  width: 50%;
}

.footer__heading h3 {
    color: #fff;
    text-align: center;
    font-weight: 600;
}

.footer__contact {
    background-color: var(--color-primary);
    padding: 40px 0;
}

.footer__contact,
.footer__contact a {
    color: #fff;
}

.footer__contact__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.footer__contact__item {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-start;
}

.footer__contact-heading {
    width: 100%;
    text-align: center;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 40px;
    font-weight: 900;
    text-transform: uppercase;
}

.footer__contact__icon {
    width: 100px;
    margin-bottom: 16px;
}

.footer__contact__icon img {
    width: 100%;
    height: auto;
    display: inline-block;
}

.footer__contact__item {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.footer__copyright {
    background-color: #22262c;
    /*background-image: url(img/footer-tempel1.png);*/
    /*background-position: left center;*/
    /*background-size: contain;*/
    /*background-repeat: no-repeat;*/
    padding: 40px 0;
    /*background: #22262c;*/
}

.footer__logo {
    width: 200px;
}

.footer__logo img {
    width: 100%;
    height: auto;
}

.footer__copyright a,
.footer__copyright {
    color: #fff;
    text-align: center;
}

.footer__copyright ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
}

ul#footer-menu li {
    display: inline-block;
}

.footer__copyright > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer__copyright {
    position: relative;
    z-index: 1;
}

.footer__copyright::before {
    content: '';
    position: absolute;
    background-image: url(img/footer-tempel1.png);
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: .3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: block;
}

@media screen and (min-width: 48rem) {
    footer {
        margin-bottom: 0;
    }

    .footer__contact__item {
        width: 33.33%;
    }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {

    .kontakt-footer-content p,
    .kontakt-footer-content a {
        font-size: var(--font-size-p);
    }

    footer::before {
        width: 65%;
        height: 100%;
    }

    .sponsor {
        width: 50%;
    }

    /*.sponsor:first-child {*/
    /*padding-right: 1rem;*/
    /*}*/
    /*.sponsor:last-child {*/
    /*padding-left: 1rem;*/
    /*}*/
}

/* viewport-xl 1280px */
@media screen and (min-width: 80rem) {
    .footer-schriftzeichen {
        position: absolute;
        right: 1rem;
        top: 1rem;
        display: block;
        text-align: right;
    }

    .footer-schriftzeichen img {
        height: 25rem;
    }

    .kontakt-footer-content {
        width: 27%;
    }

    footer::before {
        width: 75%;
        height: 100%;
    }
}

/* viewport-xxl 1600px */
@media screen and (min-width: 100rem) {
    footer::before {
        width: 48%;
    }
}

@media screen and (max-width: 725px) {
    .footer__copyright::before {
        content: none;
    }
}
