.layout-2,
.layout-3 {
    padding: 0;
    background: transparent;
    overflow: hidden;
    display: flex;
    position: relative;
    margin: 40px 0;
}

.layout-2__text-container,
.layout-3__text-container {
    background: #fff;
    padding: 40px 40px 30px 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .3);
    width: 60%;
    z-index: 3;
}

.layout-2-image__container,
.layout-3-image__container {
    overflow: hidden;
    width: 40%;
}

.layout-2 {
    justify-content: flex-end;
    padding: 230px 20px 230px 0;
}

.layout-3 {
    justify-content: flex-start;
    padding: 230px 0 230px 20px;
}

.layout-2__image:first-child,
.layout-3__image:first-child {
    position: absolute;
    top: 0;
}

.layout-2__image:first-child
 {
    left: 0;
}

.layout-3__image:first-child {
    right: 0;
}

.layout-2__image:last-child,
.layout-3__image:last-child {
    position: absolute;
    top: 50%;
}

.layout-2__image:last-child {
    left: 70px;
}

.layout-3__image:last-child {
    right: 70px;
}



.layout-2__image img,
.layout-3__image img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 767px) {
    .layout-2,
    .layout-3 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

@media screen and (max-width: 767px) {
    .layout-2, .layout-3 {
        flex-wrap: wrap;
        overflow: visible;
        padding: 0;
    }
    .layout-2-image__container, .layout-3-image__container {
        width: 100%;
        overflow: visible;
    }

    .layout-2-image__container {
        order: 2;
    }

    .layout-2__text-container, .layout-3__text-container {
        width: 100%;
    }
    .layout-2__image:nth-child(n),
    .layout-3__image:nth-child(n) {
        position: static;
        max-width: none;
    }
}



