.layout-1 {
    padding: 2rem 180px 2rem 180px;
}

/*.layout-1-twig {*/
    /*margin-top: 2rem;*/
/*}*/

.content.layout-1-twig.top-offset {
    position: relative;
    height: 210px;
}

.content.layout-1-twig.top-offset > .container {
    position: absolute;
    top: -110px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    box-shadow: 0 4px 8px rgba(0,0,0,.3);
}

@media screen and (min-width: 100rem) {
    .content.layout-1-twig.top-offset > .container {
        width: 90rem
    }
    .layout-1 {
        padding-left: 350px;
        padding-right: 350px;
    }
}


@media screen and (max-width: 1023px) {
    .content.layout-1-twig.top-offset > .container {
        max-width: 100%;
        position: static;
        transform: none;

    }
    .layout-1 {
        padding: 2rem;
    }

    .content.layout-1-twig.top-offset {
        height: auto;
    }
}

@media screen and (max-width: 767px) {
    .layout-1-twig {
        margin-bottom: 32px;
    }
}

@media screen and (max-width: 479px) {
    .layout-1 {
        padding: 16px 8px 16px 8px;
    }
}