/*
 * Filename: forms.css
 *
 * Default Styles for Forms.
 */

label,
.pure-form label {
    display: block;
}

textarea,
.pure-form textarea {
    display: block;
}

.pure-form fieldset {
    margin: 0;
    border: 0;
    padding: 0;
}

.btn {
    border-style: none;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background .7s, color .7s;
    outline: none;
}

.btn-primary {
    /*background: #e3101c; !* Old browsers *!*/
    /*background: -moz-linear-gradient(top, #e3101c 0%, #be040e 100%); !* FF3.6-15 *!*/
    /*background: -webkit-linear-gradient(top, #e3101c 0%, #be040e 100%); !* Chrome10-25,Safari5.1-6 *!*/
    /*background: linear-gradient(to bottom, #e3101c 0%, #be040e 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e3101c', endColorstr='#be040e', GradientType=0); !* IE6-9 *!*/
    /*background: var(--color-primary);*/
    color: white;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 800;
    background-color: #000;
    border-radius: 0;
    padding: 16px 24px;
}

.btn-primary:hover {
    background: var(--color-primary);
}

.btn-simple {
    background: white;
    color: var(--color-secondary);
    border: solid 1px var(--color-secondary);
}

.btn-simple:hover {
    background: var(--color-primary);
    color: white;
    border: solid 1px var(--color-primary);
}

/*.pure-form input[type="text"], .pure-form input[type="password"],*/
/*.pure-form input[type="email"], .pure-form input[type="url"],*/
/*.pure-form input[type="date"], .pure-form input[type="month"],*/
/*.pure-form input[type="time"], .pure-form input[type="datetime"],*/
/*.pure-form input[type="datetime-local"], .pure-form input[type="week"],*/
/*.pure-form input[type="number"], .pure-form input[type="search"],*/
/*.pure-form input[type="tel"], .pure-form input[type="color"],*/
/*.pure-form select, .pure-form textarea {*/
/*box-shadow: none;*/
/*border-top-style: none;*/
/*border-right-style: none;*/
/*border-bottom: 1px solid #ccc;*/
/*border-left-style: none;*/
/*}*/

/*.pure-form input[type="text"]:focus, .pure-form input[type="password"]:focus,*/
/*.pure-form input[type="email"]:focus, .pure-form input[type="url"]:focus,*/
/*.pure-form input[type="date"]:focus, .pure-form input[type="month"]:focus,*/
/*.pure-form input[type="time"]:focus, .pure-form input[type="datetime"]:focus,*/
/*.pure-form input[type="datetime-local"]:focus, .pure-form input[type="week"]:focus,*/
/*.pure-form input[type="number"]:focus, .pure-form input[type="search"]:focus,*/
/*.pure-form input[type="tel"]:focus, .pure-form input[type="color"]:focus,*/
/*.pure-form select:focus, .pure-form textarea:focus {*/
/*outline: 0;*/
/*border-color: var(--color-secondary);*/
/*}*/

/*.pure-form input[type="text"], .pure-form input[type="password"],*/
/*.pure-form input[type="email"], .pure-form input[type="url"],*/
/*.pure-form input[type="date"], .pure-form input[type="month"],*/
/*.pure-form input[type="time"], .pure-form input[type="datetime"],*/
/*.pure-form input[type="datetime-local"], .pure-form input[type="week"],*/
/*.pure-form input[type="number"], .pure-form input[type="search"],*/
/*.pure-form input[type="tel"], .pure-form input[type="color"], .pure-form textarea {*/
/*padding: .5rem .6rem .5rem 0;*/
/*}*/

/*.pure-form input[type="text"], */
/*.pure-form input[type="password"], */
/*.pure-form input[type="email"], */
/*.pure-form input[type="url"], */
/*.pure-form input[type="date"], */
/*.pure-form input[type="month"], */
/*.pure-form input[type="time"], */
/*.pure-form input[type="datetime"],*/
/*.pure-form input[type="datetime-local"], */
/*.pure-form input[type="week"], */
/*.pure-form input[type="number"], */
/*.pure-form input[type="search"], */
/*.pure-form input[type="tel"], .pure-form input[type="color"], .pure-form select, .pure-form textarea {*/
/*padding: .5rem .6rem .5rem 0;*/
/*}*/

.pure-form input[type="text"],
.pure-form input[type="password"],
.pure-form input[type="email"],
.pure-form input[type="url"],
.pure-form input[type="date"],
.pure-form input[type="month"],
.pure-form input[type="time"],
.pure-form input[type="datetime"],
.pure-form input[type="datetime-local"],
.pure-form input[type="week"],
.pure-form input[type="number"],
.pure-form input[type="search"],
.pure-form input[type="tel"],
.pure-form input[type="color"],
.pure-form select,
.pure-form textarea {
    box-shadow: none;
    margin-bottom: 40px;
    padding: 16px;
}

.pure-form textarea::placeholder,
.pure-form select,
.pure-form input::placeholder {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 15px;
}

.pure-form select {
    height: 58px;
}

select {
    color: #888888
}

.contact form#contact label {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.contact form#contact label > span {
    width: 100px;
    min-width: 100px;
    display: inline-block;
}

.contact form#contact label textarea,
.contact form#contact label select,
.contact form#contact label input {
    /*margin-right: 20px;*/
}

.contact form#contact label[for="info-form-anzeige-sonstiges"] > span,
.contact form#contact label[for="info-form-anzeige"] > span {
    min-width: 275px;
}

.contact form#contact label[for="contact-datenschutz"] {
    display: inline-block;
}

@media only screen and (max-width: 480px) {
    .pure-form button[type="submit"] {
        margin: auto;
        margin-top: 1rem;
        display: block;
    }
}