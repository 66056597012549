/*
 * Filename: base.css
 */

* {
    /*position: relative;*/
    box-sizing: border-box;
}

body {
    -webkit-font-smoothing: antialiased;
    background: var(--bg-color-body);
    /*margin-top: 3rem;*/

}

.layout-1,
.layout-2,
.layout-3,
.cta {
    background: white;
    padding: 0;
}

@media screen and (min-width: 48rem) {
    /*body {*/
        /*margin-top: 5rem;*/
    /*}*/
}

table {
    width: 100%;
    margin-bottom: var(--gutter);
}

th,
td {
    padding: .2rem;

    border-bottom: 1px solid var(--light-grey);
}

code {
    display: inline-block;
    padding: 0 .2em;
    color: var(--code);
    background: #eee;
}

a {
    color: var(--color-primary);
    text-decoration: none;
    transition: color 0.5s, background 0.5s;
}

a:hover {
    color: color(var(--color-primary) a(70%));
}

a.btn-primary {
    color: white;
}

/** Firefox Glow bei invaliden Formularfeldern entfernen */
:invalid {
    box-shadow: none;
}

:-moz-submit-invalid {
    box-shadow: none;
}

:-moz-ui-invalid {
    box-shadow: none;
}

select {
    /*-webkit-appearance: none;*/
}

.layout-1,
.layout-2,
.layout-3,
.cta {
    background: white;
}

.layout-2 {
    padding-bottom: 2rem;
}

.layout-3 {
    padding-top: 2rem;
}

.cta {
    padding: 1rem 0 2rem 0;
}


/* 1024 px - Laptop */
@media screen and (min-width: 64rem) {
    .layout-2,
    .layout-3
     {
        background: white;
        padding: 2rem;
    }

    .cta {
        padding: 1rem 0 2rem 0;
    }
}

.content.layout-1-twig.top-offset {

}