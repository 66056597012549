.preisboxen {
  margin-bottom: 2rem;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
}

.preisbox {
  width: 100%;
}

.preisbox-inner {
  margin-bottom: 0.5rem;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.preisbox--white {
  background: white;
}

.preisbox--light-grey {
  background: #a19fa4;
  color: white;
}

.preisbox--grey {
  background: #77747a;
  color: white;
}

.preisbox--red {
  background: var(--color-primary);
  color: white;
}

.preisbox-inner h2 {
  font-weight: 100;
  font-size: 32px;
}

.preis {
  font-size: 22px;
  margin-bottom: 1rem;
}

.preis--hinweis {
  width: 100%;
  margin-bottom: 1rem;
}

.preisbox-inner .btn-primary {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.55);
}

.preisbox--red .btn-primary {
  background: white;
  color: var(--color-primary);
}

.preisbox--red .btn-primary:hover {
  background: var(--color-secondary);
  color: white;
}

@media screen and (min-width: 48rem) {
  .preisbox {
    width: 50%;
  }

  .preisbox-inner {
    margin: 0.5rem;
  }
}

@media screen and (min-width: 64rem) {
  .preisbox {
    width: calc(100% / 3);
  }
  .preisbox h2 {
    min-height: 54px;
    line-height: 1.7rem;
    width: 100%; /* ie */
  }
}

@media screen and (min-width: 80rem) {
  .preisbox {
    width: 20%;
  }
  .preisbox h2 {
    min-height: 54px;
    line-height: 1.7rem;
    width: 100%;
  }
}