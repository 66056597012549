.search article {
  margin-bottom: 1rem;
}

.search {
  margin-bottom: 2rem;
}

.content a {
  color: var(--color-primary);
}

.content a.btn-primary {
  color: white;
}

.content a:hover.btn-primary {
  color: white;
}

.content a:hover {
  color: color(var(--color-primary) a(60%));
}

/*.content > article {*/
  /*margin-bottom: calc(var(--gutter) * 2);*/
  /*margin-top: calc(var(--gutter) * 2);*/
/*}*/

.content-background {
  background: white;
  padding: 1rem;
}

.layout-1 h1 {
  /*font-family: 'Julius Sans One', sans-serif;*/
  font-family: 'Proxima Nova W01';
  font-weight: 100;
  font-size: 2.2rem;
  line-height: 2.3rem;
}

.content-over-titel {
  /* padding-top: 1rem;*/
  font-size: 1rem;
  text-transform: uppercase;
  display: block;
}

.layout-2,
.layout-3 {
  text-align: center;
}

.layout-3 h1,
.layout-4 h1,
.layout-2 h1 {
  /* font-family: 'Julius Sans One', sans-serif;*/
  font-family: 'Proxima Nova W01';
  font-weight: 100;
  font-size: 1.7rem;
  margin-bottom: 1rem;
}

.layout-3 hr,
.layout-4 hr,
.layout-2 hr {
  width: 6rem;
  margin-bottom: 1rem;
  background: black;
  border-style: solid;
  border-bottom: none;
}

.layout-2 .content-left {
  /*border: solid 2px white;*/
  margin-right: 0;
  display: block;
  position: relative;
}

.layout-2 .content-left.noBorder {
  border: none;
  text-align: center;
  text-align: -webkit-center;
}

.layout-2 .content-right {
  padding: 0.5rem;
  padding-top: 0;
}

/*.layout-2 .content-triangle {*/
  /*position: absolute;*/
  /*right: 49%;*/
  /*bottom: -5px;*/
  /*content: ' ';*/
  /*width: 0;*/
  /*height: 0;*/
  /*transform: rotate(90deg);*/
  /*-webkit-transform: rotate(90deg);*/
  /*border-style: solid;*/
  /*border-width: 12px 16px 12px 0;*/
  /*border-color: transparent white transparent transparent;*/
/*}*/

/*.layout-2 .content-triangle-grey {*/
  /*position: absolute;*/
  /*right: calc(49% + 1px);*/
  /*bottom: -5px;*/
  /*content: ' ';*/
  /*width: 0;*/
  /*height: 0;*/
  /*transform: rotate(90deg);*/
  /*-webkit-transform: rotate(90deg);*/
  /*border-style: solid;*/
  /*border-width: 10px 14px 10px 0;*/
  /*border-color: transparent #e8e8e8 transparent transparent;*/
/*}*/

.layout-3 .content-right {
  /*border: solid 2px white;*/
  margin-left: 0;
  display: block;
  position: relative;
}

.layout-3 .content-right.noBorder {
  border: none;
  text-align: center;
  text-align: -webkit-center;
}

.layout-3 .content-left {
  padding: 0.5rem;
  padding-top: 0;
}

/*.layout-3 .content-triangle {*/
  /*position: absolute;*/
  /*right: 49%;*/
  /*top: -4px;*/
  /*content: ' ';*/
  /*width: 0;*/
  /*height: 0;*/
  /*transform: rotate(270deg);*/
  /*-webkit-transform: rotate(270deg);*/
  /*border-style: solid;*/
  /*border-width: 12px 16px 12px 0;*/
  /*border-color: transparent white transparent transparent;*/
/*}*/

/*.layout-3 .content-triangle-grey {*/
  /*position: absolute;*/
  /*right: calc(49% + 1px);*/
  /*top: -5px;*/
  /*content: ' ';*/
  /*width: 0;*/
  /*height: 0;*/
  /*transform: rotate(270deg);*/
  /*-webkit-transform: rotate(270deg);*/
  /*border-style: solid;*/
  /*border-width: 10px 14px 10px 0;*/
  /*border-color: transparent #e8e8e8 transparent transparent;*/
/*}*/
.layout-4 {
  text-align: center;
}
.layout-4 hr {
  border:solid #d2d2d2 1px;
  border-bottom: none;
  width: 100%;
}
.layout-4-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.layout-4-images a {
  width: 48.5%;
  margin-bottom: 0.25rem;
  margin-right: 0.25rem;
}

.layout-4-images a img {
  /*border: solid 2px white;*/
}

@media screen and (min-width: 48rem) {
  .layout-3 h1,
  .layout-4 h1,
  .layout-2 h1 {
    font-size: 2rem;
  }
  .layout-4-images a {
    width: 19%;
    /*margin-right: 0.5rem;*/
      margin-right: 15px;
    margin-bottom: 0.5rem;
  }
    .layout-4-images a:nth-child(n+5) {
        margin-right: 0;
    }
  .layout-3 .content-right.noBorder {
    text-align: left;
    text-align: -webkit-left;
  }
  .layout-2 .content-left.noBorder {
    text-align: right;
    text-align: -webkit-right;
  }
  .layout-3 {
    /*text-align: right;*/
  }

  .layout-2 {
    /*text-align: left;*/
  }

  .layout-2 hr {
    text-align: left;
    margin-left: 0;
    margin-right: auto;
  }

  .layout-3 hr {
    text-align: right;
    margin-right: 0;
    margin-left: auto;
  }

  .layout-2 .content-left {
    margin-right: 0.5rem;
  }

  .content-background {
    padding: 2rem;
  }
  /*.layout-2 .content-triangle {*/
    /*position: absolute;*/
    /*right: 0;*/
    /*top: 1.4rem;*/
    /*content: ' ';*/
    /*width: 0;*/
    /*height: 0;*/
    /*transform: rotate(360deg);*/
    /*-webkit-transform: rotate(360deg);*/
    /*border-style: solid;*/
    /*border-width: 12px 16px 12px 0;*/
    /*border-color: transparent white transparent transparent;*/
  /*}*/

  /*.layout-2 .content-triangle-grey {*/
    /*position: absolute;*/
    /*right: -2px;*/
    /*top: calc(1.4rem + 2px);*/
    /*content: ' ';*/
    /*width: 0;*/
    /*height: 0;*/
    /*transform: rotate(360deg);*/
    /*-webkit-transform: rotate(360deg);*/
    /*border-style: solid;*/
    /*border-width: 10px 14px 10px 0;*/
    /*border-color: transparent #e8e8e8 transparent transparent;*/
  /*}*/

  .layout-3 .content-right {
    margin-left: 0.5rem;
  }

  /*.layout-3 .content-triangle {*/
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 1.4rem;*/
    /*content: ' ';*/
    /*width: 0;*/
    /*height: 0;*/
    /*transform: rotate(180deg);*/
    /*-webkit-transform: rotate(180deg);*/
    /*border-style: solid;*/
    /*border-width: 12px 16px 12px 0;*/
    /*border-color: transparent white transparent transparent;*/
  /*}*/

  /*.layout-3 .content-triangle-grey {*/
    /*position: absolute;*/
    /*left: -2px;*/
    /*top: calc(1.4rem + 2px);*/
    /*content: ' ';*/
    /*width: 0;*/
    /*height: 0;*/
    /*transform: rotate(180deg);*/
    /*-webkit-transform: rotate(180deg);*/
    /*border-style: solid;*/
    /*border-width: 10px 14px 10px 0;*/
    /*border-color: transparent #e8e8e8 transparent transparent;*/
  /*}*/
}
