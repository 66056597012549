.cta {
  /*font-family: 'Julius Sans One', sans-serif;*/
  font-size: 1.4rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 4rem;
}
.cta hr {
  border:solid #d2d2d2 1px;
  border-bottom: none;
}
.cta-zitat {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-size: 1.6rem;
  line-height: 2rem;
}

.cta-zitat img{
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
}
.cta-content {
  margin-bottom: 4rem;
}
.cta-content p {
  font-size: 1.3rem;
  line-height: 1.5rem
}
.cta .btn {
  font-family: 'Proxima Nova W01';
  font-size: 18px;
  text-transform: none;
  box-shadow:0 2px 3px 0 rgba(0, 0, 0, 0.55);
  -moz-box-shadow:0 2px 3px 0 rgba(0, 0, 0, 0.55);
  -webkit-box-shadow:0 2px 3px 0 rgba(0, 0, 0, 0.55);
  margin-bottom: 1rem;
  margin-top: 1rem;
}

@media screen and (min-width: 48rem) {
  .cta-zitat {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 48rem) {
  .cta-content p { line-height: 1rem }
}
