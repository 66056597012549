.pure-g {
  display: flex;

  flex-wrap: wrap;
}

.container {
  margin: 0 0.5rem;
}


@media screen and (--viewport-lg) {
  .container {
    width: 60em;
    margin: 0 auto;
  }
}

@media screen and (--viewport-xl) {
  .container {
    width: 75em;
  }
}
