.meinungen-wrapper {
  margin-top: 1rem;

}

.meinungsbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: .5rem 1rem;
  width: 85%;
  height: 100%;
}

.meinungen-img {
  width: 100%;
}

.meinungen-text {
  width: 100%;
}

.meinungen-bild {
  display: flex;
  width: 6rem;
  height: 6rem;
  justify-content: center;
  align-self: center;
  border-radius: 5rem;
  overflow: hidden;
  margin-bottom: var(--gutter);
  margin-left: auto;
  margin-right: auto;
}

.meinungen-bild img {
  height: 100%;
  width: auto;
}

.meinungen-text p {
  margin-bottom: calc(var(--gutter) * 0.5);
}

.meinungen-name {
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: 0;
  text-transform: uppercase;
}

.swiper-button-prev.swiper-button-grau,
.swiper-container-rtl .swiper-button-prev.swiper-button-grau {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23e8e8e8'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.swiper-button-grau,
.swiper-container-rtl .swiper-button-next.swiper-button-grau {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23e8e8e8'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-prev, .swiper-button-next {
  top: 6rem;
}
.meinungs-slide {
  min-height: 887px;
}
@media screen and (min-width: 25rem) {
  .meinungs-slide {
    min-height: 480px;
  }
}
@media screen and (min-width: 48rem) {
  .meinungs-slide {
    min-height: 280px;
  }
  .meinungen-bild {
    margin-bottom: 0;
  }
  .meinungen-img {
    width: 10%;
  }

  .meinungen-text {
    width: 90%;
    padding-left: 2rem;
  }

  .swiper-button-prev, .swiper-button-next {
    top: 50%;
  }
}
@media screen and (min-width: 80rem) {
  .meinungs-slide {
    min-height: 240px;
  }
}