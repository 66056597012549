.header-image {
  margin-bottom: 1rem;
}
.header-margin {
  height: 1rem;
}
.header-image--container {
  max-height: 30rem;
  overflow: hidden;

}
.header-image--container {
  background-size:  100% auto;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.header-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}

.header-content-border {
  margin-top: 5rem;
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background: rgba(255, 255, 255, 1);
}

.header-content-inner {
  background: rgba(255, 255, 255, 1);

  padding: 1rem;
  border: solid 1px #a9a8a9;
  margin: 1rem;
  text-align: center;
}

.header-content-inner h1 {
  /*font-family: 'Julius Sans One', sans-serif;*/
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 2.5rem;
}

.header-content-inner h1 {
  display: block;
  margin-top: 2rem;
  /*font-family: 'Julius Sans One', sans-serif;*/
}

.header-over-titel {
  border-bottom: solid 1px #dedede;
}

@media screen and (min-width: 35.5rem) {
  .header-content {
    top: 40%;
  }
}

@media screen and (min-width: 48rem) {
  .header-over-titel {
    padding: 1rem;
  }
  .header-content-inner h1 {
    /*font-family: 'Julius Sans One', sans-serif;*/
    font-size: 2.2rem;
  }
  .header-image--container {
    max-height: 20rem;
    width: 100%;
    overflow: hidden;
  }

  .header-image--container img {
    width: 100%;
    display: block;
  }

  .header-content-border {
    width: 80%;
    margin: 10rem 0 0;
  }

  .header-content-inner {
    border: solid 1px #a9a8a9;
    margin: 1rem;
  }
}

@media screen and (min-width: 64rem) {
  .header-content-border {
    width: 70%;
  }

}

@media screen and (min-width: 72rem) {
  .header-content-border {
    width: 55%;
  }
}

@media screen and (min-width: 80rem) {
  .header-content-border {
    width: 50%;
  }
}

@media screen and (min-width: 90rem) {
  .header-content-border {
    width: 45%;
  }
}